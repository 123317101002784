body {
  /* margin: 3px; */
  margin: 0px;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Noto Kufi Arabic',
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.font_K24KurdishBold-Bold {
  font-family: 'K24KurdishBold-Bold';
}

/* Layout */

.footer-copy {
  background-color: #333333;
  padding: 15px;
  color: #a29f9f;
  font-weight: 600;
  font-size: 18px;
}
.scroll-button {
  position: relative;
  text-align: right;
  /* margin: 0 20px 20px; */
  height: 5px;
  font-size: 3rem;
  margin-top: -10px;
  padding-bottom: 45px;
  padding-right: 20px;

  cursor: pointer;
  color: #6b391e;
}

.box ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

/* Common */

select {
  padding: 0.2rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1rem;
}

/* Header */
.site-container {
  min-height: 100vh;
}

.Wrapper {
  /*display:inline-block; */
  flex-direction: row;
  font-weight: bold;
}
.Wrapper span {
  opacity: 0;
  animation-name: fadein;
  animation-duration: 6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.Wrapper span:nth-child(1) {
  animation-delay: 0.2s;
}
.Wrapper span:nth-child(2) {
  animation-delay: 0.3s;
}
.Wrapper span:nth-child(3) {
  animation-delay: 0.4s;
}
.Wrapper span:nth-child(4) {
  animation-delay: 0.5s;
}
.Wrapper span:nth-child(5) {
  animation-delay: 0.6s;
}
.Wrapper span:nth-child(6) {
  animation-delay: 0.7s;
}
@keyframes fadein {
  50% {
    opacity: 1;
    transform: scale(1) translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg);
    filter: blur(0px);
  }
  60% {
    opacity: 0;
    transform: scale(2) translateX(100px) skewY(10deg) skewX(10deg)
      rotateZ(30deg);
    filter: blur(10px);
  }
  75% {
    opacity: 0;
    transform: scale(2) translateY(-100px) skewY(10deg) skewX(10deg)
      rotateZ(30deg);
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg);
    filter: blur(0px);
  }
}
/* Carousel */

.button-banner {
  padding: 8px;
  background-color: #41414d;
  width: 180px;
  font-size: 20px;
  font-weight: 600;
}
.button-banner:hover {
  background-color: #8d5f4a;
  color: #f2f2f2;
}
.title {
  margin-bottom: 20px;
}

.break-div {
  border-bottom: 2px solid #808080;
  margin-top: 32px;
}

.titlebanner {
  font-size: 45px;
  font-weight: 900;
  color: #ffffff;
}
.carouselcaption p {
  color: #ffffff;
}
.button {
  padding: 10px;
  font-style: 20px;
  background-color: transparent;
}

.product-section {
  /* padding: 10px; */
  margin: 0;
  border: 0;
  text-align: center;
  /* border-top-left-radius: 50px;
  border-top-right-radius: 50px; */
  background-color: #ffffff;
  height: 100%;
}
.product-section-all {
  /* padding: 10px; */
  margin-top: -20px;
  /* border: 0; */
  text-align: center;
  /* border-top-left-radius: 50px; */
  /* border-top-right-radius: 50px; */
  background-color: #ffffff;
  height: auto;
  width: 100%;
}

.product-page-non {
  margin-top: 30px;
  height: 100vw;
}

.banner-img {
  border-radius: 50px;
  width: 100%;
  height: auto;
  align-items: center;
  text-align: center;
}
.category_name_all {
  background-color: #c1986c !important;
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
  height: 70px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-bottom: 20px;
  /* margin-top: 20px; */
}

.category_name {
  /* background-color: #6b391e !important; */
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  /* margin-bottom: 20px; */
}
.category_noname {
  background-color: #c1986c !important;
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
  height: 80px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.categoryTitle {
  background-color: #6b391e !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  /* height: 50px; */
  right: 0;
  width: 80%;
  margin-top: 10px;
  justify-content: end;
  /* text-align: center; */
  /* margin-bottom: 5px; */
  /* margin-top: 10px; */
  /* justify-content: center;
  align-items: center; */
}

.h1-title {
  color: #6b391e;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 40px;
  padding-bottom: 10px;
  text-align: center;
  border-bottom: 1px solid #e3cfc6;
}

.category-title {
  /* color: #6b391e; */
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  text-align: center;
}



.category-title-all {
  color: #6b391e;
  font-size: 32px;
  font-weight: 700;
  margin-top: 20px;
  padding-top: 16px;
  text-align: center;
}

.categoryAllTitle {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  padding: 10px;

  /* margin-top: 10px; */
  text-align: center;
  justify-content: center;
  align-items: center;
}

.h2-title {
  color: #6b391e;
  font-size: 23px;
  font-weight: 600;
  padding: 20px;
  text-align: center;
}
/*Shipping Info*/

.body_section {
  background-color: #c1986c transparent;
}

.shipping-info {
  font-size: 1em;
  color: #f9c882;
  background-color: #c1986c;
  /* margin: -3px; */
}

img.small {
  max-width: 5rem;
  width: 100%;
}

img.large {
  width: 100%;
  height: 500px;
  object-fit: contain;
  padding: 1rem;
}
.small-img-group {
  width: 100%;
  height: 100%;

  object-fit: cover;
}
.image-group {
  justify-content: center;
  height: 100%;
}
.noborder {
  border: 0;
}

.small-img-col {
  flex-basis: 24%;
  cursor: pointer;
}
.small-img {
  width: 100%;
}
.small-img-category {
  max-width: 7rem;
  text-align: center;
}
.small-img-category-list {
  max-width: 3rem;
  text-align: center;
}

.box-product {
  /* height: 30px; */
  color: rgb(0, 0, 0);
  background: transparent;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  border: 0px #c9c7c4 solid;
  margin-bottom: 35px;
  position: relative;
}

/* Card */

.preview-color-link {
  color: #000000;
}
.preview-color-link:hover {
  color: #ffffff;
}
.box .discount-label {
  color: #ffffff;
  width: 50px;
  height: 50px;
  margin: 15px 15px;
  background: #8d5f4a;
  border: 1px #8d5f4a solid;
  border-radius: 50%;
  list-style: none;
  position: absolute;
  text-align: center;
  padding: 11px 0px;
}

.preview-color {
  color: #41414d;
  width: 40px;
  height: 40px;
  margin-bottom: 3px;
  background: #f2eae7;
  border-radius: 50%;
  list-style: none;
  padding: 7px;
  cursor: pointer;
}
.preview-color:hover {
  color: #ffffff;
  background-color: #41414d;
}
#registerTipv {
  border-radius: 0;
  width: auto;
  padding: 8px;
}
.price-color {
  font-weight: 600;
  font-size: 19px;
  color: #8d5f4a;
  /* text-align: center; */
}

.text-color {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
}

.title-name {
  text-align: center;
  /* padding: 2px; */
  margin-top: 6px;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.title-name:hover {
  color: #4a4745;
}

.position-relative {
  position: relative;
}
.img-over {
  z-index: 10;
  top: -30px;
  left: -20px;
  width: 70px;
  height: 70px;
  /* background-color: #6b391e; */
  color: #fff;
  border-radius: 50px;

  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  /* display: flex;  */
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.feature {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 60px 0 20px 0;
}
.products {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.row-product {
  width: 100%;
}

/* section */
.container-adv {
  background-color: #f7f8fa;
  text-align: center;
  margin-top: 0;
}

.container-section {
  background-color: #f9c882;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px;
}
.container-section-bottom {
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
  margin-top: 32px;
}

.section-info {
  margin-top: 10px;
}
.section-info-bottom {
  margin-top: 60px;
}
.bottom-text {
  font-size: 30px;
  font-weight: 500;
}

.rating span:last-child {
  color: #404040;
}

/* images */
button.thumbnail {
  padding: 0;
  border: 0;
}
.img-large {
  max-width: 100%;
}
/* Alert */
.loading {
  display: block !important;
}
.success {
  color: #20a020;
}
.danger {
  color: #a02020;
}
.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}
/* Form */

/* Checkout Steps */

/*pagination */
.row-number {
  justify-content: center;
  padding-left: 5px;
}

.slider-category {
  display: flex;

  width: 100%;
}
.App-slider {
  /* font-family: sans-serif; */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Navbar */
.navbar {
  background: transparent;
  color: #e8e8e8 !important;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid #ddd8d8; */
}
.navbar.scroll {
  background: #e8e8e8;
  color: rgb(255, 255, 255) !important;
  opacity: 0.97;
}
.navbar-brand {
  font-size: 24px;
  font-weight: 600;
  color: rgb(0, 0, 0) !important;
  padding: 0px 30px 8px;
  text-decoration: none;
}
.title-section {
  font-size: 24px;
  font-weight: 600;
  padding: 0px 30px 8px;
}

.brand-footer {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff !important;
  padding: 0px 0px;
  text-decoration: none;
}

.brand-footer-logo span {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff !important;
  padding: 0px 0px;
  text-decoration: none;
}

.brand-footer-logo p {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff !important;
  padding: 4px 42px;
  text-decoration: none;
  text-wrap: wrap;
}
.brand span {
  font-size: 32px;
  font-weight: 800;
  color: #ee7204;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.navbar-brand.scroll {
  color: rgb(0, 0, 0) !important ;
}
.nav-button {
  color: rgb(252, 252, 252) !important ;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.nav-button-toggle {
  color: rgb(29, 27, 27) !important ;
  background-repeat: no-repeat;
}
.nav-button-toggle-icon {
  background-color: #ee232b !important ;
}

.nav-button.scroll {
  color: rgb(255, 255, 255) !important ;
}
.navbar-nav {
  padding: 10px;
  width: 100%;
}
.nav-link {
  color: rgb(25, 24, 24) !important  ;
  margin-left: 8px;
  font-size: 18px;
  font-weight: 600;
}

.nav-link.scroll {
  color: rgb(25, 24, 24) !important  ;
}
.nav-link:hover {
  color: #a2a3aa !important;
}
.header {
  position: absolute;

  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.title-header {
  /* width: 100%; */
  display: flex;
  color: #532a25;
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  justify-content: space-between;
}

.arrow-div {
  display: flex;
  justify-content: space-between;
}

.arrow-header {
  /* width: 100%; */
  display: flex;
  color: #532a25;
  font-size: 32px;
  font-weight: 500;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  width: 25px;
}
.text-color :hover {
  color: #efe6dc;
}

.bg-image {
  height: 25vw;
  width: auto;
}
.img-logo {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  /* min-height: 32vw; */
  border: 0;
}
.imgHeaderService {
  /* position: relative; */
  /* width: 100%;
  height: 100%; */
  justify-content: center;
  align-items: center;
  /* justify-items: center; */
}
.bodycontact {
  background: #eff1f3 !important;
}
.formcontact {
  background: #eff1f3 !important;
}
.input_text {
  background: #ffffff !important;
  border: 0;
}
.textcolor {
  color: #fff;
}

.z-glossy {
  z-index: 1000;
  font-size: 3vw;
  margin-top: 8vw;
}

.header-job {
  font-size: 2vw;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000;
  opacity: 0.6;
}
.overlayService {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000;
  opacity: 0.5;
}

.content-other {
  /* position: absolute; */
  /* top: 25%;
  left: 30%; */
  color: #fff;
  font-size: 30px;
  /* justify-content: center;
  align-items: center; */
}

.content {
  position: absolute;
  top: 25%;
  left: 10%;
  color: #fff;
  font-size: 30px;
  justify-content: center;
  align-items: center;
}

.projectCard {
  margin: 10px 10px;
}
.content h6 {
  font-size: 60px;
  font-weight: 700;
}

.content span {
  font-size: 1em;
  /* color: #f69821; */
  color: #f9c882;
}

/* Section */

.aboutdiv {
  min-height: 70vh;
}
.bgabout-image {
  /* background-image: url('/public/images/backabout.jpg'); */
  min-height: 30vh;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.contact_form {
  justify-content: center;
  align-items: center;
}
.bgcontact-image {
  /* background-image: url('/public/images/contactback.jpg'); */
  min-height: 30vh;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
}

.transparent {
  height: 100%;
  width: 100%;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.8);
}
.text-justify {
  font-size: 18px;
  text-align: justify;
  padding: 12px;
}

.img-section {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.img-product {
  /* position: relative; */
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.img-icon {
  position: flex;
  width: 170px;
  margin: 0 0px 0 0px;
}

.languageIcons {
  color: #fff;
}
.text-link {
  text-decoration: none;
  color: #f69821;
}
.text-link:hover {
  color: #f9c882;
}

.send-btn {
  text-decoration: none;
  background-color: #827d7f;
  color: #fff;
}
.send-btn:hover {
  background-color: #ed1c24;
}
.img-contact {
  border-radius: 40px 0;
}

.reveal {
  position: relative;
  transform: translateY(10px);
  opacity: 0.5;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

/* Card */

.card {
  flex-direction: row;
  max-width: 30em;
  border: 0;
  margin-bottom: 4px;
  border-radius: 0.7em;
  background-color: #efe6dc;
  color: #000000;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
}
.card img {
  max-width: 35%;
  margin: auto;
  /* padding: 0.5em; */
  border-radius: 0.7em;
}
.card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text-section {
  width: 100%;
  margin: -10px;
}
.cta-section {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.card-title {
  letter-spacing: 0em;
  font-size: 14px;
  margin-top: 2px;
}
.price-currency {
  font-size: 16px;
  font-weight: 500;
  color: #6b391e;
}

.price-title {
  margin-bottom: -4px;

  font-size: 20px;
  font-weight: 600;
}

/* Carousel */

.carousel-slide {
  padding: 1rem;
  margin-top: 20px;
  color: #fff;
  /* height: 100%; */
  max-width: 100vw;
  text-align: center;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #ffffff;
}

.service-name {
  font-size: 15px;
  font-weight: 500;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}
.slick-slide div {
  margin: 0 0.5rem;
}

.box {
  color: rgb(0, 0, 0);
  /* background: #ffffff; */
  overflow: hidden;
  margin-bottom: 5px;
}
.box:hover {
  box-shadow: 0 0 0px rgba(151, 147, 147, 0.1);
  opacity: 0.5;
  border: 0;
  /* border: 3 #c3bfba solid; */
  /* border-radius: 15px; */
}
.box-img {
  width: 100%;
  justify-content: center;
  color: rgb(0, 0, 0);
  background: #ffffff;
  font-family: 'Ubuntu', sans-serif;
  overflow: hidden;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  filter: alpha(opacity=60);
}

.box-img:hover {
  opacity: 0.6;
  filter: alpha(opacity=100);
}
@media only screen and (max-width: 1024px) {
  .box {
    margin: 0 0 20px;
  }
}
